@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* --background: #ffffff; */
  --text: #000;
  --primary: #ab0000;
  --neutral: #fafafa;
}

body { 
  font-family: 'Inter', sans-serif !important;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --primary: #ab0000;
    --neutral: #fafafa;
  }
}

@layer base {
  ul, ol {
    list-style: revert;
    padding-left: revert;
  }
}

@media screen and (min-width: 1024px){
  html {
    font-size: 14px;
    /* scroll-snap-type: y mandatory; TEMPORARILY DISABLED */
  }
  
  main > section {
    scroll-snap-align: start;
  }
}


@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.masked {
  mask-image: linear-gradient(black, black), url(../../public/assets/Text.svg);
  mask-composite: exclude;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
}
